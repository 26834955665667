import service from "../service.js";

//查询辅助工具出入库信息
export const searchAuxilCrkInfo = params => {
    return service({
        method: 'POST',
        url: '/auxiliaryCrk/search',
        params
    })
}


export const ckInventory = (data) => {
    return service({
        method: 'POST',
        url: '/auxiliaryCrk/ckInventory',
        data
    })
}

export const rkInventory = (data) => {
    return service({
        method: 'POST',
        url: '/auxiliaryCrk/rkInventory',
        data
    })
}

export const rkDelay = (data) => {
    return service({
        method: 'POST',
        url: '/auxiliaryCrk/rkDelay',
        data
    })
}

export const getCkInfo = (auxCode) => {
    return service({
        method: 'GET',
        url: '/auxiliaryCrk/getCkInfo',
        params: {
            auxCode
        }
    })
}

export const queryCrkInfo = (id,type) => {
    return service({
        method: 'GET',
        url: '/auxiliaryCrk/queryCrkInfo',
        params: {
            id,
            type
        }
    })
}





